<script lang="ts" setup>
import { computed, nextTick, reactive, ref, watch } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import Todo from '@/components/widgets/todo/index.vue';
import Calculator from '@/components/widgets/calculator/index.vue';
import Calendar from '@/components/widgets/calendar/index.vue';
import Weather from '@/components/widgets/weather/index.vue';
import { genUuid, genQueryString } from '@/utils/util';
import { components } from '@/configs/component';
import {
  WIDGET_MODE,
  WIDGET_COMPONENT_NAME,
  WIDGET_SIZE,
  Widget,
  WidgetMarketMaterials,
} from '@/types';
import {
  WIDGET_DESC_MAP,
} from '@/configs/quick-chain';
import AssetHelper from '@/utils/asset-helper';
import { TodoItem } from '@/types/pref';
import Toast from '@/components/toast';
import lcLog from '@/lib/lclog';

type WidgetState = {
  [key in WIDGET_COMPONENT_NAME]: number;
};

const searchWord = ref('');
// const activeIndex = ref(0);
// const groups = ref(['全部']);
const widgetMarkets = ref<WidgetMarketMaterials[]>(components);

const emit = defineEmits(['add']);
const state = reactive<WidgetState>({
  weather: 0,
  calculator: 0,
  todo: 0,
  calendar: 0,
  site: 0,
});
const props = defineProps({
  addedWidgets: {
    type: Array,
    default: () => [],
  },
});
const isComponentReady = ref(false);

const markets = computed<WidgetMarketMaterials[]>(() => {
  if (!searchWord.value.trim()) return widgetMarkets.value;
  return widgetMarkets.value.filter(widget => widget.title.includes(searchWord.value));
});

if (markets.value.length > 0) {
  nextTick(() => {
    setTimeout(() => {
      isComponentReady.value = true;
      isComponentReady.value = false;
    }, 0);
    setTimeout(() => {
      isComponentReady.value = true;
    }, 60);
  });
}


const checkSiteAdded = (widget: Widget) => props.addedWidgets.find((item: any) => widget.component === item.component);


const showNavigation = (component: WIDGET_COMPONENT_NAME) => [WIDGET_COMPONENT_NAME.weather,
  WIDGET_COMPONENT_NAME.calendar].includes(component);

const onAddComponentClick = async (widget: WidgetMarketMaterials) => {
  try {
    const id = genUuid();
    const size = state[widget.component] ? state[widget.component] : 0;
    const newWidget = {
      ...widget,
      id,
      size: widget.size[size as number],
    };

    if (widget.component === WIDGET_COMPONENT_NAME.todo) {
      newWidget.componentData = {
        id,
        component: WIDGET_COMPONENT_NAME.todo,
        name: '极简便签',
        updateTime: new Date().getTime(),
        children: [{
          id: `todo_${genUuid()}`,
          content: '添加一个新的事项',
          finished: false,
          updateTime: new Date().getTime(),
        }],
        show: true,
      } as TodoItem;

      await AssetHelper.updateComponentData(WIDGET_COMPONENT_NAME.todo, id, newWidget.componentData, true);
    }

    emit('add', newWidget);
  } catch (e) {
    lcLog.error(e);
  }
};
const prev = (widget: WidgetMarketMaterials) => {
  if (!state[widget.component]) {
    state[widget.component] = 0;
  }
  if (state[widget.component] > 0) {
    state[widget.component] = state[widget.component] - 1;
  }
};

const next = (widget: WidgetMarketMaterials) => {
  if (!state[widget.component]) {
    state[widget.component] = 0;
  }
  if (state[widget.component] < widget.size.length - 1) {
    state[widget.component] = state[widget.component] + 1;
  }
};

const getWidgetProps = (widget: Widget, size: WIDGET_SIZE): Widget => ({
  ...widget,
  size,
});
</script>
<template>
  <div class="widget-market-container">
    <div class="form-content">
      <input
        v-model="searchWord"
        type="text"
        class="form-input create-widget-search-input"
        placeholder="请输入想要搜索的组件"
      >
      <i class="icon-search" />
    </div>
    <!-- <div class="widget-group-list">
      <div
        v-for="(group, index) in groups"
        :key="index"
        :class="[activeIndex === index ? 'active' : '', 'widget-group']"
        @click="onGroupClick(index)"
      >
        {{ group }}
      </div>
    </div> -->
    <div
      v-if="isComponentReady"
      class="widget-market-list"
    >
      <div
        v-for="widget in markets"
        :key="widget.id"
        class="widget-market-item"
      >
        <div class="market-top">
          <div
            :class="[
              'market-arrow',
              'market-arrow-left',
              showNavigation(widget.component) ? 'show-navigation' : '',
              !state[widget.component] && 'disabled'
            ]"
            @click="prev(widget)"
          />
          <div class="widget-list h-full">
            <Carousel
              v-model="state[widget.component]"
              :itemsToShow="1"
              :wrapAround="false"
            >
              <Slide
                v-for="(size, index) in widget.size"
                :key="`${size}_${index}`"
              >
                <div :class="['widget-item', `icon-size-${size}`]">
                  <Todo
                    v-if="widget.component === WIDGET_COMPONENT_NAME.todo"
                    :mode="WIDGET_MODE.preview"
                    :showName="false"
                    :widget="{
                      ...widget,
                      size,
                    }"
                  />
                  <Calculator
                    v-else-if="
                      widget.component === WIDGET_COMPONENT_NAME.calculator
                    "
                    :mode="WIDGET_MODE.preview"
                    :showName="false"
                    :widget="{
                      ...widget,
                      size,
                    }"
                  />
                  <Weather
                    v-else-if="
                      widget.component === WIDGET_COMPONENT_NAME.weather
                    "
                    :mode="WIDGET_MODE.preview"
                    :showName="false"
                    :widget="getWidgetProps(widget, size)"
                  />
                  <Calendar
                    v-else-if="
                      widget.component === WIDGET_COMPONENT_NAME.calendar
                    "
                    :mode="WIDGET_MODE.preview"
                    :showName="false"
                    :widget="getWidgetProps(widget, size)"
                  />
                </div>
              </Slide>
            </Carousel>
          </div>
          <div
            :class="[
              'market-arrow',
              'market-arrow-right',
              showNavigation(widget.component) ? 'show-navigation' : '',
              state[widget.component] >= widget.size.length - 1 && 'disabled'
            ]"
            @click="next(widget)"
          />
        </div>
        <div class="market-mid">
          <div
            v-if="!checkSiteAdded(widget)"
            class="operation"
            dt-eid="add_components_button"
            :dt-params="genQueryString({
              component: widget.component,
              add_button_status: 1,
              size: widget.size[state[widget.component] as number]
            })"
            @click="onAddComponentClick(widget)"
          />
          <div
            v-else
            class="operation-added"
            dt-eid="add_components_button"
            :dt-params="genQueryString({
              add_button_status: 2,
              component: widget.component,
              size: widget.size[state[widget.component] as number]
            })"
          >
            已添加
          </div>
        </div>
        <div class="widget-footer">
          <h2 class="widget-title">
            {{ widget.title }}
          </h2>
          <p class="widget-desc">
            {{ WIDGET_DESC_MAP[widget.component] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import "./index.less";
</style>
