
import { GroupItem } from '@/types/pref';
import { computed, ref, Ref } from 'vue';

export const MAX_GROUP_SIZE = 8;

export const GROUP_ITEM_HEIGHT = 48;

export default function useNavigate(groups: Ref<GroupItem[]>) {
  const navIndex = ref(0);
  const hoverIndex = ref(-1);
  const navNode = ref();

  const setNavIndex = (index: number) => {
    navIndex.value = index;
  };

  const navGroups = computed(() => {
    const result = [];
    for (let i = 0; i < groups.value.length; i += MAX_GROUP_SIZE) {
      const chunk = groups.value.slice(i, i + MAX_GROUP_SIZE);
      result.push(chunk);
    }

    if (navIndex.value && navIndex.value >= result.length) {
      setNavIndex(result.length - 1);
    }

    return result;
  });

  const draggableGroups = computed(() => navGroups.value[navIndex.value]);

  const getRealIndex = (index: number) => navIndex.value * MAX_GROUP_SIZE + index;

  return { navIndex, setNavIndex, hoverIndex, navGroups, getRealIndex, navNode, draggableGroups };
}
