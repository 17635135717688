<script lang="ts">
import draggable from 'vuedraggable';
import ContextmenuGroupModal from '@/components/modal/contextmenu-group/index.vue';
import EditWidgetModal from '@/components/modal/edit-widget/index.vue';
import CreateWidgetModal from '@/components/modal/create-widget/index.vue';
import { genQueryString } from '@/utils/util';
import { defineComponent, PropType } from 'vue';
import { WIDGET_MENU_TEXTS, WIDGET_SIZE_TYPE } from '@/configs/quick-chain';
import { Position, Widget, WIDGET_COMPONENT_NAME, WIDGET_SIZE_AREA, WIDGET_SIZE_WIDTH, WIDGET_TYPE } from '@/types';
import fullTipModal from '@/plugins/full-tip-modal';
import { reporter } from '@/lib/dtReport';
import Site from './site/index.vue';
import Calendar from './calendar/index.vue';
import Todo from './todo/index.vue';
import Calculator from './calculator/index.vue';
import Weather from './weather/index.vue';
import Grid from './sort';
import AssetHelper from '@/utils/asset-helper';
import lcLog from '@/lib/lclog';
import Toast from '@/components/toast';

interface ElementEvent extends Event {
  target: HTMLInputElement;
}
interface MouseElementEvent extends MouseEvent {
  target: HTMLInputElement;
}

const MAX_WIDGET_NUM = 40;

export default defineComponent({
  name: 'WidgetComp',
  components: {
    ContextmenuGroupModal,
    EditWidgetModal,
    CreateWidgetModal,
    draggable,
    Site,
    Calendar,
    Todo,
    Calculator,
    Weather,
  },
  props: {
    siteList: {
      type: Array as PropType<Widget[]>,
      default: () => ([]),
    },
    groupIndex: {
      type: Number,
      default: 0,
    },
    widgetIndex: {
      type: Number,
      default: 0,
    },
    blankOpen: {
      required: false,
      default: true,
      type: Boolean,
    },
    redPoint: {
      default: false,
      type: Boolean,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      sites: this.siteList.slice(),
      showModal: false,
      showEditModal: false,
      showContextGroupModal: false,
      showContextWidgetModal: false,
      contextPosition: null,
      editPosition: null,
      position: null,
      drag: false,
      editIndex: -1,
      widgetData: {},
      showEditText: true,
      tempSites: [],
      movedWidget: {} as Widget,
      visibleRedPoint: false,
      redPointCls: '',
      mounted: false,
    };
  },
  computed: {
    openTarget() {
      return this.blankOpen ? '_blank' : '_self';
    },
    editText() {
      return WIDGET_MENU_TEXTS.edit;
    },
    deleteText() {
      return WIDGET_MENU_TEXTS.delete;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'widget',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    isShowAddIcon() {
      const siteLen = this.sites.reduce((acc, cur) => {
        let size = 1;
        if (cur.type === WIDGET_TYPE.COMPONENT) {
          size = WIDGET_SIZE_AREA[cur.size];
        }
        acc += size;
        return acc;
      }, 0);
      return siteLen < MAX_WIDGET_NUM;
    },
    contextGroupReportPrams() {
      const { editIndex, sites } = this;
      const wdigetData = sites[editIndex] || {};
      return {
        position: editIndex + 1,
        jump_url: wdigetData.url,
        name: wdigetData.title,
        group_id: this.groupIndex,
        group_name: this.groupIndex,
        group_position: this.groupIndex,
      };
    },
    contextGroupReportPramsStr() {
      return genQueryString(this.contextGroupReportPrams);
    },
    appendDom() {
      return document.getElementsByClassName('widget-list')[0];
    },
  },
  mounted() {
    this.showRedPoint();
    this.mounted = true;
    this.$bus.$on('addWidget', () => {
      this.showModal = this.widgetIndex === this.groupIndex;
    });
  },
  methods: {
    getComponentReportParams(site: Widget) {
      return site.component ? genQueryString({
        component: site.component,
        size: site.size,
      }) : '';
    },
    getComponentNameByType(site: Widget) {
      if (!site.type) {
        return WIDGET_COMPONENT_NAME.site;
      }
      return WIDGET_COMPONENT_NAME[site.component];
    },
    getPropsByType(site: Widget, index: number) {
      return {
        widget: site,
        index,
        blankOpen: this.blankOpen,
        groupIndex: this.groupIndex,
      };
    },
    getComponentSize(site: Widget) {
      if (!site.size) {
        return WIDGET_SIZE_TYPE['1x1'];
      }
      return WIDGET_SIZE_TYPE[site.size];
    },
    changeSiteList(list: any) {
      this.sites = list;
    },
    onWidgetContextMenu(e: MouseElementEvent, index: number) {
      e.preventDefault();
      const rect = (e.currentTarget as MouseElementEvent['target']).getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      let leftPos = 0;
      const { left, width } = rect;
      if (viewportWidth - (left + width) < 100) {
        leftPos = left - 100 - 8;
      } else {
        leftPos = rect.left + document.documentElement.scrollLeft + rect.width + 8;
      }
      (this.contextPosition as Position | null) = {
        top: `${rect.top + document.documentElement.scrollTop}px`,
        left: `${leftPos}px`,
      };
      this.showContextWidgetModal = true;
      this.editIndex = index;
      this.showEditText = this.sites[index].type !== WIDGET_TYPE.COMPONENT;
    },
    emitEditHandler() {
      this.showEditModal = true;
      this.showContextWidgetModal = false;
      this.widgetData = this.sites[this.editIndex];
    },
    async emitDeleteHandler() {
      try {
        const newSites = [...this.sites];
        const curWidget = newSites[this.editIndex];
        newSites.splice(this.editIndex, 1);
        await this.onChange(newSites);

        if (curWidget?.component === WIDGET_COMPONENT_NAME.todo) {
          await this.deleteUserData(curWidget);
        }
        this.showContextWidgetModal = false;
        this.sites = newSites;
      } catch (e) {
        lcLog.error('emitDeleteHandler', e);
      }
    },
    async deleteUserData(widget: Widget) {
      const { id } = widget;
      await AssetHelper.updateComponentData(WIDGET_COMPONENT_NAME.todo, id, null, true);
    },
    async saveWidget(widgetData: any) {
      try {
        const newSites = [...this.sites];
        newSites[this.editIndex] = {
          ...this.sites[this.editIndex],
          ...widgetData,
        };
        await this.onChange(newSites);
        this.sites = newSites;
        this.editIndex = -1;
        this.widgetData = {};
      } catch (e) {
        lcLog.error('saveWidget', e);
      }
    },
    showAddIcon(widget: Widget): boolean {
      const siteLen = this.sites.reduce((acc, cur) => {
        let size = 1;
        if (cur.type === WIDGET_TYPE.COMPONENT) {
          size = WIDGET_SIZE_AREA[cur.size];
        }
        acc += size;
        return acc;
      }, 0);
      let size = 1;
      if (widget.type === WIDGET_TYPE.COMPONENT) {
        size = WIDGET_SIZE_AREA[widget.size];
      }
      return siteLen + size <= MAX_WIDGET_NUM;
    },
    async addSite(site: Widget) {
      if (!this.showAddIcon(site)) {
        fullTipModal.show({
          modalValue: true,
        });
        return;
      }

      try {
        let newSites = [...this.sites];
        if (site.type === WIDGET_TYPE.COMPONENT) {
          const grid = new Grid(this.sites.slice(0));
          newSites = grid.addComponent(site);
        } else {
          newSites.push(site);
        }

        await this.onChange(newSites);
        this.sites = newSites;
        Toast.success(`添加${site.type === WIDGET_TYPE.COMPONENT ? '组件' : '快链'}成功`);
      } catch (e) {
        lcLog.error('saveWidget', e);
      }
    },
    async deleteSite(index: number) {
      try {
        const newSites = [...this.sites];
        newSites.splice(index, 1);
        await this.onChange(newSites);
        this.sites = newSites;
      } catch (e) {
        lcLog.error('saveWidget', e);
      }
    },
    showAddModal(e: Event) {
      const rect = (e as ElementEvent).target.getBoundingClientRect();
      (this.position as Position | null) = {
        top: `${rect.top}px`,
        left: `${rect.left + rect.width + 8}px`,
      };
      this.showModal = true;
      window.localStorage.setItem('show-component-red-point', 'false');
      this.visibleRedPoint = false;
      this.redPointCls = '';
    },
    // 获取最大能拖动的位置
    getMaxMovePosIdx() {
      const siteLen = this.sites.length;
      let accLen = 0;
      let maxMoveIdx = siteLen - 1;
      const { movedWidget } = this;
      const compWidth = WIDGET_SIZE_WIDTH[movedWidget.size];
      const maxPosIdx = 30 - compWidth;
      for (let index = 0; index < siteLen; index++) {
        const widget = this.sites[index];
        let size = 1;
        if (widget.type === WIDGET_TYPE.COMPONENT) {
          size = WIDGET_SIZE_WIDTH[widget.size];
        }
        accLen += size;
        if (accLen > maxPosIdx) {
          maxMoveIdx = index;
          break;
        }
      }
      return maxMoveIdx;
    },
    async onEnd(data: { moved: { newIndex: number; oldIndex: number; }; }) {
      try {
        let newSites = [...this.sites];
        const { moved: { newIndex, oldIndex } } = data;
        const { movedWidget } = this;
        const maxIdx = this.getMaxMovePosIdx();
        if (newIndex > maxIdx && movedWidget.type === WIDGET_TYPE.COMPONENT) {
          newSites = this.tempSites;
        }

        await this.onChange(newSites);
        this.sites = newSites;

        reporter.reportEvent({
          pgid: 'newpage',
          eventName: 'dt_clck',
          businessParams: {
            eid: 'quickstart_order',
            position: oldIndex + 1,
            name: this.sites[newIndex]?.title,
            jump_url: this.sites[newIndex]?.url,
            final_position: newIndex + 1,
          },
        });
      } catch (e) {
        lcLog.error('saveWidget', e);
      }
    },
    onStart(event: Event) {
      this.tempSites = this.sites.slice(0);
      this.movedWidget = this.sites[event.oldIndex];
      event.preventDefault();
    },
    onMove(event: Event) {
      event.preventDefault();
    },
    showRedPoint() {
      const redPoint = window.localStorage.getItem('show-component-red-point');
      const show = redPoint !== 'false' && this.widgetIndex === 0 && this.groupIndex === 0 && this.redPoint;
      if (show) {
        this.redPointCls = 'red-point';
        this.visibleRedPoint = true;
        // this.mounted = true;
      } else {
        this.redPointCls = '';
        this.visibleRedPoint = false;
        this.mounted = false;
      }
    },
  },
});
</script>
<template>
  <div class="widget-container">
    <div class="widget-content">
      <div class="red-point-container" />
      <draggable
        v-if="sites.length"
        ref="draggable"
        :list="sites"
        v-bind="dragOptions"
        class="widget-list"
        itemKey="title"
        @start="onStart"
        @choose="onStart"
        @move="onMove"
        @change="onEnd"
      >
        <template #item="{ element: site, index }">
          <component
            :key="index"
            :class="[
              'widget-item',
              getComponentSize(site),
            ]"
            :is="site.type ? 'div' : 'a'"
            :href="site.url"
            dt-eid="added_components"
            :dt-params="getComponentReportParams(site)"
            @contextmenu.prevent.stop="(event: any) => onWidgetContextMenu(event, index)"
          >
            <component
              :is="getComponentNameByType(site)"
              v-bind="getPropsByType(site, index)"
              class="widget-main-item"
            />
        </component>
        </template>
        <template #footer>
          <div class="widget-item icon-size-1x1">
            <!-- <div
              v-if="visibleRedPoint"
              class="tooltip"
            >
              <span>可以添加小组件啦~</span>
              <span
                class="tooltip-arrow"
                style=""
              />
            </div> -->
            <a
              v-if="isShowAddIcon"
              href="javascript:;"
              dt-imp-ignore="true"
              dt-eid="quickstart"
              dt-params="name=add-site"
              :class="[
                'add-site',
                'icon-size-1x1',
                redPointCls,
              ]"
              @click="showAddModal"
            >
              <span class="add-icon hover-state" />
            </a>
          </div>
        </template>
      </draggable>
      <div
        v-else
        class="empty-sites"
        :dt-params="contextGroupReportPramsStr"
        dt-eid="quickstart_add_entry"
        dt-keep-report="true"
        @click="showAddModal"
      >
        <i /><span>添加网站和小组件</span>
      </div>
    </div>
    <ContextmenuGroupModal
      v-if="showContextWidgetModal"
      :reportParams="contextGroupReportPrams"
      :position="contextPosition"
      :editText="editText"
      :deleteText="deleteText"
      :showEdit="showEditText"
      @edit="emitEditHandler"
      @delete="emitDeleteHandler"
      @close="showContextWidgetModal = false"
    />
    <EditWidgetModal
      v-if="showEditModal"
      :reportParams="contextGroupReportPrams"
      :widgetData="widgetData"
      @confirm="saveWidget"
      @close="showEditModal = false"
    />
    <CreateWidgetModal
      v-if="showModal"
      :reportParams="contextGroupReportPrams"
      :addedSites="sites"
      @add="addSite"
      @delete="deleteSite"
      @close="showModal = false"
    />
  </div>
</template>
<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
@import './theme.less';

.ghost {
  opacity: 0.5;
  cursor: pointer !important;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.add-component-tool-tip {
  &.el-popper.is-dark {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;

    span {
      color: #fff;
      font-size: 12px;
      line-height: 16px
    }
  }
}
</style>
./sort
